document.addEventListener('turbo:load', () => {
  const scrollers = document.querySelectorAll('.o-scroller');
  scrollers.forEach(scroller => {
    const scrollerContent = scroller.querySelector('.o-scroller__content');

    if (scroller.dataset.ready === 'true' || scrollerContent === null) {
      return;
    }
    const scrollerContentWidth = scrollerContent.getBoundingClientRect().width;
    const scrollerWidth = scroller.getBoundingClientRect().width;

    // If the content is less wide than the container, don't scroll.
    if (scrollerContentWidth <= scrollerWidth) {
      return;
    }
    // Calculate the duration of the animation based on the width of the content.
    const duration = scrollerContentWidth / 50;

    scrollerContent.innerHTML += scrollerContent.innerHTML;
    scrollerContent.style.animation = `scroller ${duration}s linear infinite`;

    // Set the ready attribute to true so we don't reinitialize the scroller if the
    // this function is called again.
    scroller.dataset.ready = 'true';
  });
});
